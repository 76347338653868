.counter {

  color: rgba(194, 193, 193, 0.884) !important;
  text-align: center;
}
.counter-content {
  display: flex;
  gap: 5px;
  margin: 0 5px;
}
.counter-content h5 {
  margin: 0 5px;
  padding: 0px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: medium;
  font-weight:500 ;

}
