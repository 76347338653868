
.mdropdown {
    color: black;
}
.mdropdown ul {
  max-height: 400px;
 
  overflow: hidden;
  overflow-y: auto;
}
