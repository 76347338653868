
.nft{
    user-select:none;
    max-width: 300px;
    margin: 0px;
    border: 1px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(0deg, rgba(40,44,52,1) 0%, rgba(17,0,32,.5) 100%);
    box-shadow: 0 7px 20px 5px #00000088;
    border-radius: .7rem;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    overflow: hidden;
    transition: .5s all;
    hr{
      width: 100%;
      border: none;
      border-bottom: 1px solid #88888855;
      margin-top: 0;
    }
    ins{
      text-decoration: none;
    }
    .main{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      h2{
        margin-top: 5px;
        font: 15px cookie, cursive;
        margin-left: 13px;
        font-weight: 700;
        color: white;
        padding: 5px;
        border-bottom: 1px solid #5a5757d1;
       min-height: 50px;
      }
      .tokenImage{
        border-radius: .5rem;
        max-width: 100%;
        height: 150px;
        object-fit: fill;
      }
      .description{
        margin: .5rem 0;
        color: #a89ec9;
        max-height: 71px;
        overflow-y: auto;
        font-size: 15px;
       // min-height: 100px;
      }
      .tokenInfo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price{
          display: flex;
          align-items: center;
          color: #ee83e5;
          font-weight: 700;
          ins{
            margin-left: -.3rem;
            margin-right: .5rem;
          }
        }
        .duration{
          display: flex;
          align-items: center;
          color: #a89ec9;
          margin-right: .2rem;
          ins{
            margin: .5rem;
            margin-bottom: .4rem;
          }
        }
      }
      .creator{
        display: flex;
        align-items: center;
        margin-top: .2rem;
        margin-bottom: -.3rem;
        ins{
          color: #a89ec9;
          text-decoration: none;
        }
        .wrapper{
          display: flex;
          align-items: center;
          border: 1px solid #ffffff22;
          padding: .3rem;
          margin: 0;
          margin-right: .5rem;
          border-radius: 100%;
          box-shadow: inset 0 0 0 4px #000000aa;
          img{
            border-radius: 100%;
            border: 1px solid #ffffff22;
            width: 2rem;
            height: 2rem;
            object-fit: cover;
            margin: 0;
          }
        }
      }
    }
    ::before{
      position: fixed;
      content: "";
      box-shadow: 0 0 100px 40px #ffffff08;
      top: -10%;
      left: -100%;
      transform: rotate(-45deg);
      height: 60rem;
      transition: .7s all;
    }
   
  }
  
  .bg{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1{
      font-size: 20rem;
      filter: opacity(0.5);
    }
  }